<template>
  <div class="home">
    <v-card class="ma-3">
      <div class="Group-Picture d-none d-sm-flex">
        <v-img class="" dark contain src="@/pictures/grouppicture.jpg">
        </v-img>
      </div>
    
      <div class="Content-01">
        <Content01 />
        <MobileContent01 />
      </div>
    </v-card>

    <v-card class="ma-3">
      <div class="Content-02 ma-0">
        <Content02 />
        <MobileContent02 />
      </div>

      <div class="Content-03">
        <Content03 />
      </div>
    </v-card>
  </div>
</template>

<script>
  import Content01 from '@/components/Home/Home_Content_01.vue'
  import Content02 from '@/components/Home/Home_Content_02.vue'
  import Content03 from '@/components/Home/Home_Content_03.vue'
  import MobileContent01 from '@/components/Home/mobile/Mobile_Home_Content_01.vue'
  import MobileContent02 from '@/components/Home/mobile/Mobile_Home_Content_02.vue'

  export default {
    name: 'Home',
    components: { Content01, Content02, Content03, MobileContent01, MobileContent02}
  }
</script>
