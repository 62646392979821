<template>
    <div>
        <!--Navigation Drawer-->
        <v-navigation-drawer v-model="drawer" temporary app>            
            <v-list class="pa-0">

                <v-list-item to="/">
                    <div style="max-width: 100%;">
                        <v-list-item-content>
                            <v-img src="@/assets/logo.png"></v-img>
                        </v-list-item-content>
                    </div>
                </v-list-item>
                <v-divider class="grey"></v-divider>
                <v-list-item 
                    v-for="link in links"
                    :key="link.links"
                    :to="link.route"
                >
                    <v-list-item-title>
                        <v-list-item-content class="title">
                            {{link.name}}
                        </v-list-item-content>
                    </v-list-item-title> 
                </v-list-item>
                <v-list-item
                    v-if="loggedIn"
                    href="https://www.flickr.com/"
                    target="_blank"
                >
                    <v-list-item-title>
                        <v-list-item-content class="title">
                            Flickr
                        </v-list-item-content>
                    </v-list-item-title> 
                </v-list-item>

                <template v-if="loggedIn && this.role.includes('ROLE_ADMIN')">
                    <v-list-item  to="/" class="grey darken-4">
                        <v-list-item-content class="text-uppercase grey--text text--lighten-3 text-h4">
                            <span class="font-weight-light">Admin</span>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item to="/admin">
                        <v-list-item-title class="title">Admin</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/register">
                        <v-list-item-title class="title">Register</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        v-for="link in adminLinks"
                        :key="link.links"
                        :href="link.route"
                        target="_blank"
                    >
                        <v-list-item-title>
                            <v-list-item-content class="title">
                                {{link.name}}
                            </v-list-item-content>
                        </v-list-item-title> 
                    </v-list-item>
                </template>
            </v-list>      
            <template v-slot:append> 
                <v-divider></v-divider>
                <v-row no-gutters class="text-center pa-2">
                    <v-col cols="6">
                        <v-btn class="mx-4" large icon target="_blank" href="https://www.facebook.com/Buergerkapelle/">
                            <v-icon medium size="24px">mdi-facebook</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col cols="6">
                        <v-btn class="mx-4 pa-3" large icon target="_blank" href="https://www.instagram.com/buergerkapelle_badischl/">
                            <v-icon medium size="24px">mdi-instagram</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>   
        </v-navigation-drawer>

        <!--App Bar-->
        <v-app-bar dark app>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title class="text-uppercase grey--text text--lighten-3 display-1">
                <span class="font-weight-light">Bürger</span>
                <span>Kapelle</span>   
                <span class="font-weight-light"> Bad Ischl</span>          
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <Admin />

            <Signin />
        </v-app-bar>

        

        <!--Snackbar popup-->
        <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<script id="CookieDeclaration" src="https://consent.cookiebot.com/df7afde2-d37b-46b4-a03d-55a25e0a4f62/cd.js" type="text/javascript" async></script>

<script>
    import { links, adminLinks } from '@/variables/index.js'
    import Signin from '@/components/Login/signin.vue'
    import Admin from '@/components/Login/addEvent.vue'

    export default {
        components: { Signin, Admin },
        data() {
            return {
                //Drawers
                drawer: false,
                //Dialogs
                
                //Snackbar
                snackbar: false,
                ErrorMessage: "",
                errorType: "info",
                timeout: 2000,
                links: links,
                //Display Buttons

                //login
                loggedIn: false,
                adminLinks: adminLinks,
                
                role: [],
            }
        },
        computed: {
            
        },
        methods: {
            checkIfLoggedIn() {
                if(localStorage['user']) {
                    this.loggedIn = true
                    let user = JSON.parse(localStorage.getItem('user'));
                    this.role = user.roles
                }
                else {
                    this.loggedIn = false
                    this.userList = []
                    this.role = []
                }
            },
        },
        created() {
            this.interval = setInterval(() => this.checkIfLoggedIn(), 500);
        },
    }
</script>