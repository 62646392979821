<template>
    <div class="SignIn">
        <v-btn v-if="showLogoutBtn" @click="logout" class="d-none d-md-flex d-md-none">Logout ({{this.currUser}})</v-btn>
        <v-btn v-if="!showLogoutBtn" @click="signinDialog=true" class="d-none d-md-flex d-md-none">Login</v-btn>

        <!--Signin Dialog-->
        <v-dialog v-model="signinDialog" width="30%">
            <v-card>
                <v-card-title class="red darken-4 white--text text-h6">
                    Anmelden
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-text-field
                        v-model="username"
                        label="Benutzername"
                        required
                        :error-messages="usernameErrors"
                        @input="$v.username.$touch()"
                        @blur="$v.username.$touch()"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        label="Passwort"
                        required
                        :error-messages="passwordErrors"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                    ></v-text-field>

                </v-card-text>

                <v-card-actions>
                    <v-btn @click="clear">
                    clear
                    </v-btn>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="ma-2"
                                color="grey"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <span>Noch kein Account? Einfach an einen IT-Referenten wenden!</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                    
                    <v-btn @click="submit" class="mr-4" color="success">
                    Anmelden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--Snackbar popup-->
        <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<script>
//import Vue from 'vue'
//import axios from 'axios'
import {authPrefix, url} from '@/variables/index.js'
import { validationMixin } from 'vuelidate'
import { required, minLength} from 'vuelidate/lib/validators'
import authHeader from '@/functions/index.js'

export default {

    mixins: [validationMixin],

    validations: {
        username: {required},
        password: {required, minLength: minLength(6)},
    },
    computed: {
        passwordErrors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('Password is required!')
            !this.$v.password.minLength && errors.push('Password must be at least 6 characters long!')
            return errors
        },
        usernameErrors () {
            const errors = []
            if (!this.$v.username.$dirty) return errors
            !this.$v.username.required && errors.push('Username is required"')
            return errors
        },
    },
    data() {
        return{
            //Login
            username: "",
            password: "",
            show1: false,
            //Snackbar
            snackbar: false,
            ErrorMessage: "",
            timeout: 2000,
            errorType: "info",

            signinDialog: false,
            showLogoutBtn: false,

            currUser: "",
      }
    },
    methods: {
        signin() {
            // POST request using axios with set headers
            if (this.username && this.password != null) {
                const item = { "username": this.username, "password": this.password };
                const headers = { 
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                };
                this.axios.post(url + authPrefix + "/signin", item, { headers })
                .catch((err) => {
                    this.ErrorMessage = err.response.data.message
                    this.errorType = "error"
                    this.snackbar = true
                })
                .then(response => {
                    if(response) {
                        if(response.data.accessToken) {
                            localStorage.setItem('user', JSON.stringify(response.data));
                        }
                        this.currUser = response.data.username
                        this.ErrorMessage = "Logged in!"
                        this.errorType = "success"
                        this.snackbar = true;
                        return response.data;
                    }
                })         
            }       
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'Error'
            }
            else {
                this.signin()
                this.clear()
                this.signinDialog = false
            }     
        },
        clear () {
            this.$v.$reset()
            this.username = ''
            this.password = ''
        },
        logout() {
            if(!localStorage['user']) {
                this.ErrorMessage = "You are not logged in!"
                this.errorType = "info"
            }
            else {
                localStorage.removeItem("user")
                this.ErrorMessage = "Logged out!"
                this.errorType = "info"
            }
            this.snackbar = true;
        },
        checkIfLoggedIn() {
            if(localStorage['user']) {
                this.showLogoutBtn = true
                this.currUser = JSON.parse(localStorage.getItem('user')).username
                this.axios.get(url + authPrefix + "/verify", {headers: authHeader()})
                .catch((err) => {
                    this.ErrorMessage = err.response.data.message
                    this.ErrorType = "error"
                    this.snackbar = true
                    this.currUser = ""
                    localStorage.removeItem("user")
                    return
                });
            }
            else {
                this.showLogoutBtn = false
                localStorage.removeItem("user")
            }
        },
    },
    created() {
        this.interval = setInterval(() => this.checkIfLoggedIn(), 1000);
    },
  }
</script>