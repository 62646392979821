export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
  
    if (user && user.accessToken) {
      // for Node.js Express back-end
      var header = {'x-access-token': user.accessToken, "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",}
      return(header)
    } else {
      return
    }
}