<template>
    <v-row class="d-none d-sm-flex" no-gutters><!--Removes the Spacing between the different row items-->
        <v-col cols="6" class="grey darken-4">
            <v-carousel cycle>
                <!--Slide 1-->
                <v-carousel-item 
                    v-for="(picture,i) in pictures" 
                    :key="i" 
                    :src="picture.src"
                ></v-carousel-item>                
            </v-carousel>
        </v-col>
        
        <v-col cols="6">
            <v-container fill-height fluid>
                <v-row 
                class="fill-height" 
                align="center" 
                justify="center"
                >
                    <v-col>
                        <v-layout child-flex>
                            <v-slide-group show-arrows>
                                <v-slide-item>
                                    <v-card
                                        class="ma-4"
                                        min-width="250"
                                        width="auto"
                                        height="auto"
                                    >
                                        <v-card-title class="pa-8 text-h5 font-weight-bold white--text red darken-4"><v-row align="center" justify="center">Bürgerkapelle</v-row></v-card-title>

                                        <v-divider></v-divider>

                                        <v-card-text class="ma-0 text-subtitle-1">
                                            <span class="font-weight-medium">Bürgerkapelle Bad Ischl</span><br>
                                            Schulgasse 15<br>
                                            4820 Bad Ischl
                                        </v-card-text>

                                        <v-card-text class="text-subtitle-1">
                                            <span class="font-weight-medium">E-Mail:</span><br> <a href="mailto:buergerkapelle@aon.at">buergerkapelle@aon.at</a>
                                        </v-card-text>
                                    </v-card>
                                </v-slide-item>
                                <v-slide-item
                                    v-for="item in contact"
                                    :key="item.contact"
                                >
                                    <v-card
                                        class="ma-4"
                                        min-width="250"
                                        width="auto"
                                        height="auto"
                                    >
                                        <v-card-title class="pa-8 text-h5 font-weight-bold white--text red darken-4"><v-row align="center" justify="center">{{item.title}}</v-row></v-card-title>

                                        <v-card-text class="pa-3 text-h5 font-weight-medium">
                                            {{item.name}}   
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-text class="ma-0 text-subtitle-1">
                                            <span class="font-weight-medium">Tel.:</span><br> {{item.tel}}
                                        </v-card-text>

                                        <v-card-text class="text-subtitle-1">
                                            <span class="font-weight-medium">E-Mail:</span><br> <a :href="'mailto:' + item.email">{{item.email}}</a>
                                        </v-card-text>
                                    </v-card>
                                </v-slide-item>

                            </v-slide-group>
                        </v-layout>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import {slideImages_02, contacts} from '@/variables/index.js'

export default {
    components: {},
    data() {
        return {
            model: null,
            contact: contacts,
            pictures: slideImages_02 ,
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    created() {
        
    },
}
</script>