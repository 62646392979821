<template>
    <div>
      <v-row class="d-none d-sm-flex" no-gutters><!--Removes the Spacing between the different row items-->
          <v-col cols="6">
            <v-container fill-height fluid>
              <v-row  
                align="center" 
                justify="center"
              >
                <v-col>
                  <v-layout child-flex>
                    <div v-if="!loggedIn">
                      <div v-if="Object.keys(eventList).length != 0">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Termin suchen"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-data-table
                          :search="search"
                          :headers="headers"
                          :items="eventList"
                          :footer-props=footerProps
                          :options=dataTableOptions
                          sort-by="date"
                          :sort-desc="true"
                        >
                          <template v-slot:[`item.date`]="{ item }">
                            <span v-if="new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString() > item.date" class="grey lighten-3 text-decoration-line-through">{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                            <span v-if="new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString() <= item.date">{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                          </template>
                        </v-data-table>
                      </div>

                      <div v-else>
                        <span>Keinen Termin gefunden!</span>
                      </div>
                    </div>

                    <div v-if="loggedIn">
                      <div v-if="Object.keys(eventList).length != 0">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-data-table
                          :search="search"
                          :headers="loggedInHeaders"
                          :items="eventList"
                          :footer-props=footerProps
                          :options=dataTableOptions
                          sort-by="date"
                          :sort-desc="true"
                        >
                          <template v-slot:[`item.date`]="{ item }">
                            <span v-if="new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString() > item.date" class="grey lighten-3 text-decoration-line-through">{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                            <span v-if="new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString() <= item.date">{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn small color="warning" dark @click="clear(); Id = item._id; event = item.event; date = item.date; time = item.time; editDialog ='true';"><v-icon small>mdi-pencil</v-icon></v-btn>       
                            <v-btn small color="error" @click="deleteEvent(item._id);"><v-icon small>mdi-delete</v-icon></v-btn>
                          </template>  
                        </v-data-table>
                      </div>

                      <div v-else>
                        <span>Keinen Termin gefunden!</span>
                      </div>
                    </div>
                  </v-layout>
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <v-col cols="6" class="grey darken-4">
            <v-carousel cycle>
                  <!--Slide 1-->
                  <v-carousel-item 
                      v-for="(picture,i) in pictures" 
                      :key="i" 
                      :src="picture.src"
                  ></v-carousel-item>
              </v-carousel>
          </v-col>
      </v-row>

      <!--Edit Dialog-->
      <template>
        <!--@click="editItem(item._id, item.item, item.itemType);" -->
        <v-dialog v-model="editDialog">
          <form>
            <v-card>
              <v-card-title class="red darken-4 white--text text-h6">
                Edit Post

                <v-spacer></v-spacer>

                <v-btn dark class="" @click="editDialog=!editDialog" large icon><v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>

              <v-card-text class="ma-3">
                  <v-row> 
                      <v-col>          
                          <v-text-field
                              v-model="newEventName"
                              label="Termin"
                              required
                              :error-messages="newNameErrors"
                              :counter="100"
                              @input="$v.newEventName.$touch()"
                              @blur="$v.newEventName.$touch()"
                          ></v-text-field>
                      </v-col>  
                      <v-col cols="2">
                          <v-btn class="ma-3 pa-1" @click="pinOnOff = !pinOnOff; keepOld(1)" large icon><v-icon>{{ !pinOnOff ? 'mdi-pin' : 'mdi-pin-off'}}</v-icon></v-btn>
                      </v-col>
                  </v-row> 
              </v-card-text>

              <v-card-text>
                  <v-row>
                    <v-col align="center">
                      Aktuelles Datum: {{new Date(this.date).toLocaleDateString("de-DE")}}<br>
                      <v-date-picker
                          color="grey darken-2"
                          :first-day-of-week="1"
                          locale="de"
                          @click:date="newDateSelected"
                          v-model="newDate"
                          required
                          :error-messages="newDateErrors"
                          @input="$v.newDate.$touch()"
                          @blur="$v.newDate.$touch()"
                      ></v-date-picker>
                    </v-col>
                    <v-col align="center">
                      Aktuelle Zeit: {{this.time}}<br>
                      <v-time-picker
                          color="grey darken-2"
                          format="24hr"
                          @click:date="newTimeSelected"
                          v-model="newTime"
                          required
                          :error-messages="newTimeErrors"
                          @input="$v.newTime.$touch()"
                          @blur="$v.newTime.$touch()"
                      ></v-time-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                      <v-text-field
                          :error-messages="newDateErrors"
                          @input="$v.newDate.$touch()"
                          @blur="$v.newDate.$touch()"
                          disabled
                      >
                      </v-text-field>
                  </v-col>
                  <v-col>
                      <v-text-field
                          :error-messages="newTimeErrors"
                          @input="$v.newTime.$touch()"
                          @blur="$v.newTime.$touch()"
                          disabled
                      >
                      </v-text-field>
                  </v-col>
              </v-row>
                </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn @click="clear">
                  clear
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn color="warning" @click="submit">Edit</v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </v-dialog>
      </template>

      <!--Snackbar popup-->
      <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<script>
  import {slideImages_01, url, eventPrefix} from '@/variables/index.js'

  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'

  import authHeader from '@/functions/index.js'

  export default {
    mixins: [validationMixin],

    validations: {
      newEventName: {required, maxLength: maxLength(100)},
      newDate: {required},
      newTime: {required},
    },

    components: {},
    data() {
        return {
          eventList: [],

          dataTableOptions: {
            itemsPerPage: 6,
            page: 2,
          },

          footerProps: {
                'items-per-page-options':[6],
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-plus',
                nextIcon: 'mdi-minus'
            },

          today: "",
          yesterday: "",

          showCurrEventButton: false,

          headers: [
            {text: 'Termin', value: 'event', class: 'title font-weight-bold'},
            {text: 'Datum', value: 'date', class: 'title font-weight-bold'},
            {text: 'Zeit', value: 'time', class: 'title font-weight-bold'},
          ],

          loggedInHeaders: [
            {text: 'Termin', value: 'event', class: 'title font-weight-bold'},
            {text: 'Datum', value: 'date', class: 'title font-weight-bold'},
            {text: 'Zeit', value: 'time', class: 'title font-weight-bold'},
            {text: 'Actions', value: "actions", align: 'center', class: 'title font-weight-bold'},
          ],

          editDialog: false,

          search: "",

          pictures: slideImages_01 ,

          loggedIn: false,

          newEventName: "",
          newDate: "",
          newTime: "",

          Id: "",
          event: "",
          date: "",
          time: "",

          //Pin Icon On-Off
          pinOnOff: false,

          //Snackbar
          snackbar: false,
          ErrorMessage: "",
          errorType: "info",
          timeout: 2000,
        }
    },
    computed: {
        newNameErrors () {
          const errors = []
          if (!this.$v.newEventName.$dirty) return errors
          !this.$v.newEventName.required && errors.push('Name is required!')
          return errors
        },
        newDateErrors () {
          const errors = []
          if (!this.$v.newDate.$dirty) return errors
          !this.$v.newDate.required && errors.push('Date is required!')
          return errors
        },
        newTimeErrors () {
          const errors = []
          if (!this.$v.newTime.$dirty) return errors
          !this.$v.newTime.required && errors.push('Time is required!')
          return errors
        },
      },
    methods: {
      setup() {
        this.today = new Date()
        this.yesterday = new Date(this.today.setDate(this.today.getDate() - 1))
        //console.log(new Date(new Date(new Date().setDate(new Date().getDate() - 1))))
      },

      keepOld(_id) {
          if(_id == 1){
              if(this.newEventName != this.event) {
                  this.newEventName = this.event
              } else {
                  this.newEventName = ""
              }
          }
      },

      checkIfLoggedIn() {
          if(localStorage['user']) {
              this.loggedIn = true
          }
          else {
              this.loggedIn = false
          }
      },

      newDateSelected (date) {
          this.newDate = date
      },

      newTimeSelected (time) {
          this.eventTime = time
      },

      async getEvents() {
        const headers = { 
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        };
        await this.axios.get(url + eventPrefix + "/events", { headers }).then((response) => {
          this.eventList = response.data
        })
        .catch((err) => {
          this.ErrorMessage = err.response.data.message
          this.errorType = "error"
          this.snackbar = true;
        })

        this.calculateTablePage()
      },

      async deleteEvent(id) {
        // POST request using axios with set headers
        const event = { id: id };
        await this.axios.post(url + eventPrefix +"/deleteEvent", event, { headers: authHeader() })
        .catch((err) => {
          this.ErrorMessage = err.response.data.message
          this.errorType = "error"
          this.snackbar = true;
        });
        this.ErrorMessage = "Termin gelöscht!"
        this.errorType = "success"
        this.snackbar = true
        this.getEvents() 
      },

      async editEvent() {
        if (this.newEventName && this.newDate != null) {
          // POST request using axios with set headers
          const event = { id: this.Id,newEvent: this.newEventName, newDate: this.newDate, newTime: this.newTime, };
          await this.axios.post(url + eventPrefix + "/editEvent", event, { headers: authHeader() })
          .catch((err) => {
            this.ErrorMessage = err.response.data.message
            this.snackbar = true;
          });
          this.editDialog = false;
          this.ErrorMessage = "Temin bearbeitet!"
          this.errorType = "success"
          this.snackbar = true; 
        }
        this.getEvents()      
      },

      calculateTablePage() {
            var date = new Date().toISOString()
            //Event with the latest date
            var maxDate
            //Event with the earliest date
            var minDate
            //Variable counts every Event that is later than the current date
            var countToMax = 0

            var x
            for (x = 0; x < this.eventList.length; x++) {
                //Initially set the max- and minDates
                if(!maxDate) {
                    maxDate = this.eventList[x].date
                }

                if(!minDate) {
                    minDate = this.eventList[x].date
                }

                //Count every Event that is later than today
                if(this.eventList[x].date > date) {
                    countToMax = countToMax + 1
                }

                //Get the latest and earliest Date
                if( this.eventList[x].date > maxDate) {
                    maxDate = this.eventList[x].date
                }

                if( this.eventList[x].date < minDate) {
                    minDate = this.eventList[x].date
                }
            }
            var startingPage = Math.ceil((countToMax) / this.dataTableOptions.itemsPerPage)
            if(countToMax % this.dataTableOptions.itemsPerPage == 0) {
                startingPage = startingPage + 1
            }
            this.dataTableOptions.page = startingPage
        },

      submit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'Error'
        }
        else {
          this.editEvent()
          this.clear()
        }     
      },
      clear () {
        this.$v.$reset()
        this.newEventName = ''
        this.newDate = ''
      },
    },
    created() {
      this.setup()
      this.interval = setInterval(() => this.checkIfLoggedIn(), 500);
      this.interval = setInterval(() => this.getEvents(), 10000)
    },

    beforeMount() {
      this.getEvents()        
    }
}
</script>