<template>
    <v-row class="d-flex d-sm-none" no-gutters><!--Removes the Spacing between the different row items-->
        <v-col>
            <v-row 
                class="fill-height" 
                align="center" 
                justify="center"
            >
                <v-col>
                    <h1 class="text-center pt-4 text-h4">Termine</h1>
                    <v-layout child-flex>
                        <div v-if="Object.keys(eventList).length != 0">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Termin suchen"
                                single-line
                                hide-details
                                class="ma-4"
                            ></v-text-field>
                            <v-data-table
                                :search="search"
                                :headers="headers"
                                :items="eventList"
                                :footer-props=footerProps
                                :options=dataTableOptions
                                sort-by="date"
                                :sort-desc="true"
                            >
                                <template v-slot:[`item.date`]="{ item }">
                                    <span v-if="new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString() > item.date" class="grey lighten-3 text-decoration-line-through">{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                                    <span v-if="new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString() <= item.date">{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                                </template>
                            </v-data-table>
                        </div>

                        <div v-else>
                            <span>Keinen Termin gefunden!</span>
                        </div>
                    </v-layout>
                </v-col>
            </v-row>    
        </v-col>
    </v-row>
</template>

<script>
  import { url, eventPrefix} from '@/variables/index.js'

  export default {
    components: {},
    data() {
        return {
            eventList: [],

            dataTableOptions: {
                itemsPerPage: 2,
                page: 2,
            },

            footerProps: {
                'items-per-page-options':[2],
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-plus',
                nextIcon: 'mdi-minus'
            },

            search: "",
            loggedIn: false,

            today: "",
            yesterday: "",

            headers: [
                {text: 'Termin', value: 'event', class: 'title font-weight-bold'},
                {text: 'Datum', value: 'date', class: 'title font-weight-bold'},
                {text: 'Zeit', value: 'time', class: 'title font-weight-bold'},
            ],
        }
    },
    computed: {
        
    },
    methods: {
        setup() {
            this.today = new Date()
            this.yesterday = new Date(this.today.setDate(this.today.getDate() - 1))
            //console.log(new Date(new Date(new Date().setDate(new Date().getDate() - 1))))
        },

        checkIfLoggedIn() {
            if(localStorage['user']) {
                this.loggedIn = true
            }
            else {
                this.loggedIn = false
            }
        },

        async getEvents() {
            const headers = { 
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
            };
            await this.axios.get(url + eventPrefix + "/events", { headers }).then((response) => {
                this.eventList = response.data
            })
            .catch((err) => {
                this.ErrorMessage = err.response.data.message
                this.snackbar = true;
            })

            this.calculateTablePage()
        },

        calculateTablePage() {
            var date = new Date().toISOString()
            //Event with the latest date
            var maxDate
            //Event with the earliest date
            var minDate
            //Variable counts every Event that is later than the current date
            var countToMax = 0

            var x
            for (x = 0; x < this.eventList.length; x++) {
                //Initially set the max- and minDates
                if(!maxDate) {
                    maxDate = this.eventList[x].date
                }

                if(!minDate) {
                    minDate = this.eventList[x].date
                }

                //Count every Event that is later than today
                if(this.eventList[x].date > date) {
                    countToMax = countToMax + 1
                }

                //Get the latest and earliest Date
                if( this.eventList[x].date > maxDate) {
                    maxDate = this.eventList[x].date
                }

                if( this.eventList[x].date < minDate) {
                    minDate = this.eventList[x].date
                }
            }
            var startingPage = Math.ceil((countToMax) / this.dataTableOptions.itemsPerPage)
            if(countToMax % this.dataTableOptions.itemsPerPage == 0) {
                startingPage = startingPage + 1
            }
            this.dataTableOptions.page = startingPage
        },
    },

    created() {
        this.setup()
        this.interval = setInterval(() => this.checkIfLoggedIn(), 500);
        this.interval = setInterval(() => this.getEvents(), 10000)
    },

    beforeMount() {
        this.getEvents()        
    }
}
</script>