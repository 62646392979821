export const authPrefix = "auth" //Prefix for all Authentication related calls
export const eventPrefix = "api" //Prefix for all Event related calls
export const url = "https://buergerkapelle.org:8443/"//"https://192.168.3.112:8443/"//"https://alexanderjuric.com:8443/"//"https://89.58.39.50:8443/" //url of the api

export const links = [
    { name: 'Home', route: '/', icon: 'mdi-home', },
    { name: 'Galerie', route: '/galerie', icon: 'mdi-image-outline', },
    { name: 'Chronik', route: '/chronik', icon: 'mdi-history', },
    { name: 'Impressum', route: '/impressum', icon: 'mdi-information-outline', },
]

export const adminLinks = [
    { name: 'Webmail', route: 'https://asmp.a1.net/asmp/LoginMasterServlet?userRequestURL=https%253A%252F%252Fasmp.a1.net%252Fmsslogin%253FserviceId%253DSTART%2526returnId%253D&serviceRegistrationURL=&service=MSSLoginService&wrongLoginType=false&cookie=skip&level=10', icon: 'mdi-cog', },
    { name: 'Wiki', route: 'https://buergerkapelle.atlassian.net/wiki/home', icon: 'mdi-cog', },
    { name: 'JIRA', route: 'https://buergerkapelle.atlassian.net/', icon: 'mdi-cog', },
    { name: 'Datenbank', route: 'https://www.mongodb.com', icon: 'mdi-cog', },
    { name: 'Portainer', route: 'https://89.58.39.50:9443/', icon: 'mdi-cog', },
    { name: 'NetCup', route: 'https://www.customercontrolpanel.de/start.php', icon: 'mdi-cog', },
]

export const gruppenbild = [
    { date: "", desc: "Gruppenfoto 2020", src: require('@/pictures/grouppicture.jpg'), },
]

export const bezirksehrung2017 = [
    { date: "", desc: "Bezirksehrung 2017", src: require('@/pictures/galerie/2017_bezirksehrung/bezirksehrung_2017.jpg'), },
]

export const slideImages_01 = [
    { date: "07.12.2019", desc: "", src: require('@/pictures/slides/nikolauskonzert.jpg'), },
    { date: "23.12.2019", desc: "", src: require('@/pictures/slides/weihnachtsfeier.jpg'), },
]

export const slideImages_02 = [
    { date: "20.02.2019", desc: "", src: require('@/pictures/slides/fasching.jpg'), },
    { date: "03.08.2019", desc: "", src: require('@/pictures/slides/jugendausflug.jpg'), },
]

export const contacts = [
    { title: 'Obmann', name: 'Josef Leithner', tel: '0664 760 00 82', email: 'josefleithner@gmx.at' },
    { title: 'Kapellmeister', name: 'Christian Binder', tel: '0699 107 087 79', email: 'c_binder@aon.at' },
    //{ title: 'Kassier', name: 'Alois Graf', tel: '0660 149 28 28', email: 'alois.graf@gmx.at'},
    { title: 'Jugendreferentin', name: 'Lena Oitzinger', tel: '0699 192 619 22', email: 'lena.oitzinger@gmail.com' },
    { title: 'Jugendreferentin', name: 'Elena Raffler', tel: '0676 506 35 72', email: 'elenamariaraffler@gmail.com' },
    { title: 'Jugendreferentin', name: 'Tanja Kainzner', tel: '0664 885 328 79', email: 't.kainzner@gmail.com' },
    //{ title: 'Stabführer', name: 'Andreas Binder', tel: '0699 171 53 418', email: 'binder97andi@gmail.com'},               
    //{ title: 'Obmann Stv.', name: 'Christian Graf', tel: '0660 12 707 87', email: 'graf.ch@gmx.at'},                        
    //{ title: 'Kapellmeister Stv.', name: 'Yvonne Gschwandtner', tel: '0660 766 75 93', email: 'ygschwandtner@gmail.com'},
]