<template>
    <div class="addEvent">
        <v-btn v-if="loggedIn" class="white--text success ma-2" @click="addDialog=!addDialog; clear()">
            <v-icon>mdi-plus</v-icon>  
        </v-btn>

        <v-dialog v-model="addDialog">
            <v-toolbar dark>
                <v-tabs 
                    v-model="tab"
                    align-with-title
                    color="red darken-4"
                    grow
                >
                    <v-tabs-slider color="red darken-4"></v-tabs-slider>
                    <v-tab class="text-h6 white--text">Termin hinzufügen</v-tab>
                    <v-tab class="text-h6 white--text">Album hinzufügen</v-tab>
                    <v-tab v-if="this.role.includes('ROLE_ADMIN')" class="text-h6 white--text">Kontakt hinzufügen</v-tab>
                </v-tabs>

                <v-spacer></v-spacer>

                <v-btn class="ma-3 pa-1" @click="addDialog=!addDialog" large icon><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-tabs-items
                v-model="tab"
            >
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <v-text-field
                                v-model="eventName"
                                label="Termin"
                                required
                                :error-messages="eventNameErrors"
                                :counter="100"
                                @input="$v.eventName.$touch()"
                                @blur="$v.eventName.$touch()"
                            ></v-text-field>

                        </v-card-text>

                        <v-card-text>
                            <v-row>
                                <v-col align="center" justify="center">
                                    <v-date-picker
                                        color="grey darken-2"
                                        :first-day-of-week="1"
                                        locale="de"
                                        @click:date="dateSelected"
                                        v-model="eventDate"
                                        required
                                        :error-messages="eventDateErrors"
                                        @input="$v.eventDate.$touch()"
                                        @blur="$v.eventDate.$touch()"
                                    ></v-date-picker>
                                </v-col>
                                <v-col align="center" justify="center">
                                    <v-time-picker
                                        color="grey darken-2"
                                        format="24hr"
                                        @click:date="timeSelected"
                                        v-model="eventTime"
                                        required
                                        :error-messages="eventTimeErrors"
                                        @input="$v.eventTime.$touch()"
                                        @blur="$v.eventTime.$touch()"
                                    ></v-time-picker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :error-messages="eventDateErrors"
                                        @input="$v.eventDate.$touch()"
                                        @blur="$v.eventDate.$touch()"
                                        disabled
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :error-messages="eventTimeErrors"
                                        @input="$v.eventTime.$touch()"
                                        @blur="$v.eventTime.$touch()"
                                        disabled
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn @click="clear">
                            clear
                            </v-btn>

                            <v-spacer></v-spacer>
                            
                            <v-btn @click="submit" class="mr-4" color="success">
                            Termin hinzufügen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="albumName"
                                        label="Album"
                                        required
                                        :error-messages="albumNameErrors"
                                        :counter="100"
                                        @input="$v.albumName.$touch()"
                                        @blur="$v.albumName.$touch()"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="albumSrc"
                                        label="Bild"
                                        required
                                        :error-messages="albumSrcErrors"
                                        :counter="100"
                                        @input="$v.albumSrc.$touch()"
                                        @blur="$v.albumSrc.$touch()"
                                    ></v-text-field>
                                    <v-text-field
                                        class="mb-3"
                                        v-model="albumLink"
                                        label="Link"
                                        required
                                        :error-messages="albumLinkErrors"
                                        :counter="100"
                                        @input="$v.albumLink.$touch()"
                                        @blur="$v.albumLink.$touch()"
                                    ></v-text-field>

                                    <v-divider></v-divider>

                                    <section class="ma-3">
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Daten erkennen"
                                                    v-model="flickrString"
                                                    :error-messages="this.flickrErrors"
                                                >

                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn class="ma-3 pa-1" @click="searchFlickrString(3)" large icon><v-icon>mdi-magnify</v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-expansion-panels>
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header>
                                                            Hilfe beim erkennen der Daten (Flickr):
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content>
                                                            <v-list
                                                                class="mx-auto"
                                                            >
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            1. Zum <strong><a href="https://www.flickr.com" target="_blank">Flickr Album</a></strong> navigieren
                                                                        </v-list-item-title>
                                                                        
                                                                        <v-list-item-subtitle>
                                                                            Dort das gewünschte Album auswählen!
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            2. Auf <strong>Album Teilen</strong> klicken!
                                                                        </v-list-item-title>
                                                                        
                                                                        <v-list-item-subtitle>
                                                                            <v-img max-width="400" src="@/pictures/help/flickr/share_album.png"></v-img>
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            3. <strong>Code kopieren</strong>!
                                                                        </v-list-item-title>
                                                                        
                                                                        <v-list-item-subtitle>
                                                                            <v-img max-width="400" src="@/pictures/help/flickr/get_embed_string.png"></v-img>
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            4. Code in <strong>"Daten erkennen"</strong> einfügen!
                                                                        </v-list-item-title>
                                                                        
                                                                        <v-list-item-subtitle>
                                                                            Mit der Lupe die Daten automatisch erkennen!
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </section>
                                </v-col>
                                <v-col cols="4" align="center" justify="center">
                                    <v-date-picker
                                        color="grey darken-2"
                                        :first-day-of-week="1"
                                        locale="de"
                                        v-model="albumDate"
                                        required
                                        
                                        @input="$v.albumDate.$touch()"
                                        @blur="$v.albumDate.$touch()"
                                    ></v-date-picker>
                                    <v-text-field
                                        :error-messages="albumDateErrors"
                                        @input="$v.albumDate.$touch()"
                                        @blur="$v.albumDate.$touch()"
                                        disabled
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn @click="clear">
                            clear
                            </v-btn>

                            <v-spacer></v-spacer>
                            
                            <v-btn @click="submitAlbum" class="mr-4" color="success">
                            Album hinzufügen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>

                <v-tab-item v-if="this.role.includes('ROLE_ADMIN')">
                    <v-card>
                        <v-card-text>
                            In Arbeit...
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-dialog>

        <!--Snackbar popup-->
        <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<script>
    //import Vue from 'vue'
    //import axios from 'axios'
    import { eventPrefix, url} from '@/variables'
    import { validationMixin } from 'vuelidate'
    import { required, maxLength } from 'vuelidate/lib/validators'
    import authHeader from '@/functions/index.js'
    export default {
        mixins: [validationMixin],

        validations: {
            eventName: {required, maxLength: maxLength(100)},
            eventDate: {required},
            eventTime: {required},
            albumName: {required, maxLength: maxLength(100)},
            albumDate: {required},
            albumSrc:  {required}, 
            albumLink:  {required}, 
        },

        computed: {
            eventNameErrors () {
                const errors = []
                if (!this.$v.eventName.$dirty) return errors
                !this.$v.eventName.required && errors.push('Name is required!')
                return errors
            },
            eventDateErrors () {
                const errors = []
                if (!this.$v.eventDate.$dirty) return errors
                !this.$v.eventDate.required && errors.push('Date is required!')
                return errors
            },
            eventTimeErrors () {
                const errors = []
                if (!this.$v.eventTime.$dirty) return errors
                !this.$v.eventTime.required && errors.push('Time is required!')
                return errors
            },
            albumNameErrors () {
                const errors = []
                if (!this.$v.albumName.$dirty) return errors
                !this.$v.albumName.required && errors.push('Name is required!')
                return errors
            },
            albumDateErrors () {
                const errors = []
                if (!this.$v.albumDate.$dirty) return errors
                !this.$v.albumDate.required && errors.push('Date is required!')
                return errors
            },
            albumSrcErrors () {
                const errors = []
                if (!this.$v.albumSrc.$dirty) return errors
                !this.$v.albumSrc.required && errors.push('A link to an image is required!')
                return errors
            },
            albumLinkErrors () {
                const errors = []
                if (!this.$v.albumLink.$dirty) return errors
                !this.$v.albumLink.required && errors.push('A link is required!')
                return errors
            },
        },
        data() {
            return{
                //Adding Items
                eventName: "",
                eventDate: "",
                eventTime: "",
                albumName: "",
                albumDate: "",
                albumSrc: "",
                albumLink: "",
                loggedIn: false,
                //Snackbar
                addDialog: false,
                snackbar: false,
                ErrorMessage: "",
                errorType: "info",
                timeout: 2000,
                //Tabs
                tab: null,

                //Flickr String
                flickrString: "",
                flickrErrors: null,

                role: [],
            }
        },
        methods: {
            checkIfLoggedIn() {
                if(localStorage['user']) {
                    this.loggedIn = true
                    let user = JSON.parse(localStorage.getItem('user'));
                    this.role = user.roles
                }
                else {
                    this.loggedIn = false
                    this.role = []
                }
            },

            dateSelected (date) {
                this.eventDate = date
            },

            timeSelected (time) {
                this.eventTime = time
            },

            addEvent() {
                // POST request using axios with set headers
                if (this.eventName && this.eventDate && this.eventTime != null) {
                    const event = { event: this.eventName, date: this.eventDate, time: this.eventTime, };
                    console.log(url + eventPrefix + "/addEvent")
                    console.log(event)
                    this.axios.post(url + eventPrefix + "/addEvent", event, {headers: authHeader()})
                    .catch((err) => {
                        this.ErrorMessage = err.response.data.message
                        this.errorType = "error"
                        this.snackbar = true;
                    })
                    this.addDialog = false;
                }       
            },
            submit () {
                this.$v.eventName.$touch()
                this.$v.eventDate.$touch()
                this.$v.eventTime.$touch()
                if (this.$v.eventName.$invalid || this.$v.eventDate.$invalid || this.$v.eventTime.$invalid) {
                    this.submitStatus = 'Error'
                }
                else {
                    this.addEvent()
                    this.clear()
                }     
            },
            addAlbum() {
                // POST request using axios with set headers
                if (this.albumName && this.albumDate && this.albumSrc && this.albumLink != null) {
                    const album = { desc: this.albumName, date: this.albumDate, src: this.albumSrc, link: this.albumLink, };
                    console.log(url + eventPrefix + "/addAlbum")
                    console.log(album)
                    this.axios.post(url + eventPrefix + "/addAlbum", album, {headers: authHeader()})
                    .catch((err) => {
                        this.ErrorMessage = err.response.data.message
                        this.errorType = "error"
                        this.snackbar = true;
                    })
                    this.addDialog = false;
                }       
            },
            submitAlbum () {
                this.$v.albumName.$touch()
                this.$v.albumDate.$touch()
                this.$v.albumSrc.$touch()
                this.$v.albumLink.$touch()
                if (this.$v.albumName.$invalid || this.$v.albumDate.$invalid || this.$v.albumSrc.$invalid || this.$v.albumLink.$invalid) {
                    this.submitStatus = 'Error'
                }
                else {
                    this.addAlbum()
                    this.clear()
                }      
            },
            clear () {
                this.$v.$reset()
                this.eventName = ''
                this.eventDate = ''
                this.eventTime = ''
                this.albumName = ''
                this.albumDate = ''
                this.albumSrc = ''
                this.albumLink = ''
                this.flickrErrors = null
            },
            searchFlickrString() {
                this.flickrErrors = null
                //Link to Album
                var hrefStart = this.flickrString.indexOf('href="') + 6
                var hrefEnd = this.flickrString.indexOf('"', hrefStart)
                if(hrefEnd > hrefStart && this.flickrString.substring(hrefStart, hrefStart + 8) == "https://") {
                    this.albumLink = this.flickrString.substring(hrefStart, hrefEnd)
                }
                else {this.flickrErrors = "Fehler!"}

                //Link to Title
                var titleStart = this.flickrString.indexOf('title="') + 7
                var titleEnd = this.flickrString.indexOf('"', titleStart)
                if(titleEnd > titleStart && this.flickrString.substring(titleStart - 7, titleStart - 2) == 'title'){
                    this.albumName = this.flickrString.substring(titleStart, titleEnd)
                }
                else {this.flickrErrors = "Fehler!"}                

                //Link to Image
                var srcStart = this.flickrString.indexOf('src="') + 5
                var srcEnd = this.flickrString.indexOf('"', srcStart)
                if(srcEnd > srcStart && this.flickrString.substring(srcStart, srcStart + 8) == "https://") {
                    this.albumSrc = this.flickrString.substring(srcStart, srcEnd)
                }
                else {this.flickrErrors = "Fehler!"}               
                
                if(this.albumName != "" && this.albumLink != "" && this.albumSrc != "") {
                    this.flickrErrors = "Erfolgreich!"
                }

                this.flickrString = ""
            },
        },
        created() {
            this.interval = setInterval(() => this.checkIfLoggedIn(), 500);
        },
    }
</script>