<template>
    <v-row class="d-flex d-sm-none grey lighten-3" no-gutters><!--Removes the Spacing between the different row items--> 
        <v-col>
            <v-row 
                class="fill-height" 
                align="center" 
                justify="center"
            >
                <v-col>
                    <h1 class="text-center pt-4 text-h4">Kontakt</h1>
                    <v-layout child-flex>
                        <v-slide-group show-arrows>
                            <v-slide-item>
                                <v-card
                                    class="ma-4"
                                    width="250"
                                    height="auto"
                                >
                                    <v-card-title class="pa-8 text-h5 font-weight-bold white--text red darken-4"><v-row align="center" justify="center">Bürgerkapelle</v-row></v-card-title>

                                    <v-divider></v-divider>

                                    <v-card-text class="ma-0 text-subtitle-1">
                                        <span class="font-weight-medium">Bürgerkapelle Bad Ischl</span><br>
                                        Schulgasse 15<br>
                                        4820 Bad Ischl
                                    </v-card-text>

                                    <v-card-text class="text-subtitle-1">
                                        <span class="font-weight-medium">E-Mail:</span><br> buergerkapelle@aon.at
                                    </v-card-text>
                                </v-card>
                            </v-slide-item>
                            <v-slide-item
                                v-for="item in contact"
                                :key="item.contact"
                            >
                                <v-card
                                    class="ma-4"
                                    width="250"
                                    height="auto"
                                >
                                    <v-card-title class="pa-8 text-h5 font-weight-bold white--text red darken-4"><v-row align="center" justify="center">{{item.title}}</v-row></v-card-title>

                                    <v-card-text class="pa-3 text-h5 font-weight-medium">
                                        {{item.name}}   
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-text class="ma-0 text-subtitle-1">
                                        <span class="font-weight-medium">Tel.:</span><br> {{item.tel}}
                                    </v-card-text>

                                    <v-card-text class="text-subtitle-1">
                                        <span class="font-weight-medium">E-Mail:</span><br> {{item.email}}
                                    </v-card-text>
                                </v-card>
                            </v-slide-item>

                        </v-slide-group>
                    </v-layout>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import {contacts} from '@/variables/index.js'

export default {
    components: {},
    data() {
        return {
            model: null,
            contact: contacts,
            pictures: [
                {
                    src: require('@/pictures/slides/fasching.jpg'),
                },
                {
                    src: require('@/pictures/slides/jugendausflug.jpg'),
                },
            ],
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    created() {
        
    },
}
</script>