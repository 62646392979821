<template>
    <div class="footer">
        <v-footer
            dark
            padless
            class="d-none d-sm-flex"
        >
            <v-card
                flat
                tile
                class="dark lighten-1 white--text text-center"
                width="100%"
            >
                <v-card-text>
                    <v-btn class="mx-4 white--text" icon target="_blank" href="https://www.facebook.com/Buergerkapelle/">
                        <v-icon size="24px">mdi-facebook</v-icon>
                    </v-btn>

                    <v-btn class="mx-4 white--text" icon target="_blank" href="https://www.instagram.com/buergerkapelle_badischl/">
                        <v-icon size="24px">mdi-instagram</v-icon>
                    </v-btn>
                </v-card-text>

                <v-card-text>
                    <v-row
                    justify="center"
                    no-gutters
                    >
                        <v-btn
                        v-for="link in links"
                        :key="link.links"
                        :to="link.route"
                        color="white"
                        text
                        rounded
                        class="my-2 ma-1"
                        >
                            {{link.name}}
                        </v-btn>
                        <v-btn
                            color="white"
                            text
                            rounded
                            class="my-2 ma-1"
                            href="mailto:buergerkapelle@aon.at"
                        >
                            Kontaktiere uns
                        </v-btn>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="white--text">
                    {{ new Date().getFullYear() }} — <strong>Bürgerkapelle Bad Ischl</strong>
                </v-card-text>
            </v-card>
        </v-footer>
        
        <v-footer 
            dark
            padless
            class="d-sm-none"
        >
            <v-card
                class="dark lighten-1 white--text text-center pa-3"
                width="100%"
            >
                <v-row
                    justify="center"
                    class="pa-1"
                >
                    <v-btn class="mx-4 white--text" icon target="_blank" href="https://www.facebook.com/Buergerkapelle/">
                        <v-icon size="24px">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn class="mx-4 white--text" icon target="_blank" href="https://www.instagram.com/buergerkapelle_badischl/">
                        <v-icon size="24px">mdi-instagram</v-icon>
                    </v-btn>
                </v-row>

                <v-row
                    justify="center"
                    class="pa-1"
                >
                    <v-btn
                    v-for="link in links"
                    :key="link.links"
                    :to="link.route"
                    color="white"
                    text
                    rounded
                    class="my-2 ma-1"
                    >
                        <v-icon>{{link.icon}}</v-icon>
                    </v-btn>
                </v-row>

                <v-divider class="ma-4"></v-divider>

                <v-row 
                    justify="center"
                    class="pa-1"
                >
                    {{ new Date().getFullYear() }} — <strong>Bürgerkapelle Bad Ischl</strong>
                </v-row>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
    import { links, } from '@/variables/index.js' 

    export default {
      data: () => ({
        links: links,
      }),
    }
</script>