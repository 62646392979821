<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>
<!-- Axios Tutorial https://medium.com/javascript-in-plain-english/implement-movie-app-with-vue-vuetify-axios-open-movie-database-api-d12290318cf9-->

<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="df7afde2-d37b-46b4-a03d-55a25e0a4f62" data-blockingmode="auto" type="text/javascript"></script>

<script>
import Navbar from "@/components/Common/navbar.vue";
import Footer from "@/components/Common/footer.vue";
export default {
  name: "App",
  components: { Navbar, Footer },
  data: () => ({}),
};
</script>