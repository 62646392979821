<template>
    <v-row class="d-none d-sm-flex" no-gutters><!--Removes the Spacing between the different row items-->
        <div style="width: 100%;">
            <Instagram/>
        </div>
    </v-row>
</template>

<script>
import Instagram from '@/components/instagram.vue'

export default {
    components: { Instagram, },
    data() {
        return {

        }
    },
    computed: {
        
    },
    methods: {
        
    },
    created() {
        
    },
}
</script>